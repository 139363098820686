<template src="./AdminAnnouncement.html"></template>

<script>
import AnnouncementOpen from "../../components/AnnouncementOpen/AnnouncementOpen";
import NewsVerticalCard from "../../components/NewsVerticalCard/NewsVerticalCard";
import AnnouncementClose from "../../components/AnnouncementClose/AnnouncementClose";
import newsService from "../../services/News";
import ArrobaMedellinNoData from "../../components/ArrobaMedellinNoData/ArrobaMedellinNoData";
import ArrobaMedellinArrobitaNoData from "../../components/ArrobaMedellinArrobitaNoData/ArrobaMedellinArrobitaNoData";
import announcementService from "../../services/Announcement";
import HorizontalCardComingSoon from "../../components/HorizontalCardComingSoon/HorizontalCardComingSoon";
import AnnouncementCloseHorizontal from "../../components/AnnouncementCloseHorizontal/AnnouncementCloseHorizontal";
import VerticalCardComingSoon from "../../components/VerticalCardComingSoon/VerticalCardComingSoon";

export default {
  name: "AdminAnnouncement",
  components: {
    AnnouncementOpen,
    NewsVerticalCard,
    AnnouncementClose,
    ArrobaMedellinNoData,
    ArrobaMedellinArrobitaNoData,
    HorizontalCardComingSoon,
    AnnouncementCloseHorizontal,
    VerticalCardComingSoon,
  },
  data() {
    return {
      itemsAllOpenCalls: 2,
      openCalls: [],
      closedCalls: [],
      allNews: [],
      institutionId: "",
      role: "",
    };
  },
  created() {
    this.institutionId = this.$store.state.User.user.institutionId.id;
    this.role = this.$store.state.User.user.role.name;
    this.getInformation();
  },
  methods: {
    async getInformation() {
      try {
        const objOpenCalls = {
          take: 5,
          skip: 0,
          state: true,
        };
        const objClosedCalls = {
          take: 5,
          skip: 0,
          state: false,
        };
        this.openCalls = await announcementService.listAnnouncement(
          objOpenCalls
        );
        this.closedCalls = await announcementService.listAnnouncement(
          objClosedCalls
        );
        this.allNews = await newsService.listNews();
      } catch (error) {
        console.error(error);
      }
    },
  },
  computed: {
    calculateCommingSoon() {
      return (quantity, max) => {
        const dif = max - quantity;
        return dif < 0 ? [] : Array(dif);
      };
    },
    roleValidation() {
      return ["AdministratorCall"].some((a) => a === this.role);
    },
  },
};
</script>

<style lang="scss" scoped src="./AdminAnnouncement.scss"></style>
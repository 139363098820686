<template src='./AnnouncementClose.html'></template>

<script>
export default {
  name: "announcement-close",
  props: {
    name: { type: String },
    dateInit: { type: String },
    dateFinish: { type: String },
    description: { type: String },
    image: { type: String },
    descriptionImg: { type: String, default: "Imagen de la convocatoria cerrada" },
    buttonText: { type: String },
    ratingQualification: { type: Number },
  },
  methods: {
    pushButton() {
      this.$emit("announcementCloseButton");
    },
  },
  computed: {
    imgUrl() {
      return this.image
        ? this.image
        : "https://firebasestorage.googleapis.com/v0/b/sapiencia-ciudadela.appspot.com/o/Img_default.png?alt=media&token=bee72947-8de9-498d-b907-0043331f997a";
    },
  },
};
</script>

<style lang="scss" scoped src="./AnnouncementClose.scss"></style>